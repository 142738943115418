<template>
  <div v-if="!$store.state.loading">
    <div v-if="screenType === 'desktop'">
      <v-snackbar
          top
          v-model="snackbar"
      >
        <div v-html="msg">

        </div>
        <template v-slot:action="{ attrs }">
          <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-row>
        <v-col cols="5">
          <v-row>
            <v-col cols="12" class="title-login-register text-left custom-padding-left">
              Login
            </v-col>
            <v-col cols="12" class="text-center mb-0 pb-0">
              <v-text-field
                  v-model="credentials.email"
                  :rules="usernameRules"
                  label="Username / Email"
                  outlined
                  class="login-fields"
                  rounded
                  color="#39bf88"
                  required>
              </v-text-field>
            </v-col>
            <v-col cols="12" class="text-center mt-0 pt-0">
              <v-text-field
                  class="login-fields"
                  type="password"
                  v-model="credentials.password"
                  :rules="passwordRules"
                  label="Password"
                  outlined
                  rounded
                  required
                  color="#39bf88"
              ></v-text-field>
            </v-col>
            <v-col
                cols="6"
                style="margin-top:-5%"
                class="pa-0">
              <v-checkbox
                  class="login-checkbox"
                  label="Remember me"
                  color="#39bf88"
                  value="remember"
                  hide-details
              ></v-checkbox>
            </v-col>
            <v-col
                cols="6"
                style="margin-top:-3.2vh"
                class="forgot-password-text text-left" @click="$router.push('forgot-password')">
              Forgot Password?
            </v-col>
            <v-col cols="12" class="text-left">
              <v-btn :disabled="!loginCondition" :color="loginCondition ? '#39bf88' : '#c8c8c8'" elevation="0"
                     class="white--text login-button text-capitalize" @click="login()">Login
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="6" class="title-login-register text-left pl-16">
          <v-row>
            <v-col cols="12" class="mb-1">
              Register
            </v-col>
            <v-col cols="6" class="text-center custom-y-margin">
              <v-text-field
                  v-model="userInstance.first_name"
                  label="First Name"
                  outlined
                  rounded
                  color="#39bf88"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="text-center custom-y-margin">
              <v-text-field
                  v-model="userInstance.last_name"
                  label="Last Name"
                  outlined
                  rounded
                  color="#39bf88"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="text-center custom-y-margin">
              <v-text-field
                  v-model="userInstance.phone"
                  label="Phone Number (Optional)"
                  outlined
                  rounded
                  color="#39bf88"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="text-center custom-y-margin">
              <v-text-field
                  type="email"
                  v-model="userInstance.email"
                  label="Email"
                  outlined
                  rounded
                  color="#39bf88"
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="text-center custom-y-margin">
              <v-text-field
                  v-model="userInstance.password"
                  type="password"
                  label="Password"
                  outlined
                  rounded
                  color="#39bf88"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="text-center custom-y-margin">
              <v-text-field
                  v-model="userInstance.password_confirmation"
                  type="password"
                  label="Confirm Password"
                  outlined
                  rounded
                  color="#39bf88"
              ></v-text-field>
            </v-col>
            <v-col
                cols="6"
                class="text-center custom-y-margin">
              <v-checkbox
                  label="I accept all the terms and privacy policy"
                  color="#39bf88"
                  v-model="terms"
                  :value="true"
                  hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
            </v-col>
            <v-col cols="12" class="text-left">
              <v-btn @click="register()" :disabled="!registerCondition"
                     :color="registerCondition ? '#39bf88' : '#c8c8c8'"
                     elevation="0"
                     class="white--text register-button text-capitalize">Register
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-snackbar
          top
          v-model="snackbar"
      >
        <div v-html="msg">

        </div>
        <template v-slot:action="{ attrs }">
          <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-row class="mt-2">
        <v-col cols="6" class="text-left">
          <v-btn @click="mobileTab = 'login'" :color="mobileTab === 'login' ? '#39bf88' : '#c7c7c8' "
                 class="text-capitalize white--text" width="190" style="border-radius: 0 20px 20px 0">
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Login
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn @click="mobileTab = 'register'" :color="mobileTab === 'register' ? '#39bf88' : '#c7c7c8' "
                 class="text-capitalize white--text" width="190" style="border-radius: 20px 0 0 20px">
            Register
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          </v-btn>
        </v-col>
      </v-row>


      <v-row v-if="mobileTab === 'register'" class="mt-6">
        <v-col cols="12" class="mt-0 pt-0">
          <v-text-field
              style="border-radius: 21px"
              class="mx-8"
              placeholder="Type Here ..."
              v-model="userInstance.first_name"
              label="First Name"
              outlined
              color="#39bf88"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" class="mt-0 pt-0">
          <v-text-field
              style="border-radius: 21px"
              class="mx-8"
              placeholder="Type Here ..."
              v-model="userInstance.last_name"
              label="Last Name"
              outlined
              color="#39bf88"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mt-0 pt-0">
          <v-text-field
              v-model="userInstance.phone"
              label="Phone Number (Optional)"
              outlined
              style="border-radius: 21px"
              class="mx-8"
              placeholder="Type Here ..."
              color="#39bf88"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" class="mt-0 pt-0">
          <v-text-field
              type="email"
              v-model="userInstance.email"
              label="Email"
              outlined
              style="border-radius: 21px"
              class="mx-8"
              placeholder="Type Here ..."
              color="#39bf88"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mt-0 pt-0">
          <v-text-field
              v-model="userInstance.password"
              label="Password"
              outlined
              style="border-radius: 21px"
              class="mx-8"
              placeholder="Type Here ..."
              color="#39bf88"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" class="mt-0 pt-0">
          <v-text-field
              v-model="userInstance.password_confirmation"
              label="Confirm Password"
              outlined
              style="border-radius: 21px"
              class="mx-8"
              placeholder="Type Here ..."
              color="#39bf88"
          ></v-text-field>
        </v-col>
        <v-col
            cols="2" class="pl-10 mt-0 pt-0 text-center" style="margin-top: -20px!important;">
          <v-checkbox
              class="mt-0 pt-0"
              color="#39bf88"
              v-model="terms"
              :value="true"
              hide-details
          ></v-checkbox>
        </v-col>
        <v-col cols="10"
               style="color:#808080;font-size: 15px;margin-top: -32px!important"
               class="text-left">
          I accept all the terms and privacy policy
        </v-col>
        <v-col cols="12" style="margin-bottom: 150px!important;">
          <v-btn height="40" :disabled="!registerCondition"
                 :color="registerCondition ? '#39bf88' : '#c8c8c8'"
                 width="80%" class="text-capitalize white--text" style="border-radius: 10px" @click="register">Register
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="mobileTab === 'login'" class="mt-6">
        <v-col cols="12">
          <v-text-field
              style="border-radius: 21px"
              class="mx-8"
              v-model="credentials.email"
              :rules="usernameRules"
              label="Username"
              placeholder="mail@google.com"
              outlined
              color="#39bf88"
              required>
          </v-text-field>
        </v-col>
        <v-col cols="12" class="mt-0">
          <v-text-field
              style="border-radius: 21px"
              class="mx-8"
              type="password"
              v-model="credentials.password"
              :rules="passwordRules"
              label="Password"
              placeholder="Password"
              required
              outlined
              color="#39bf88"
          ></v-text-field>
        </v-col>
        <v-col cols="2" class="mt-0">
          <v-checkbox
              class="mx-8 mt-0"
              color="#39bf88"
              value="remember"
              hide-details
          ></v-checkbox>
        </v-col>
        <v-col cols="10" class=" mt-1 text-left" style="color: #808080">
          Remember me
        </v-col>
        <v-col cols="12" class="mt-10">
          <v-btn height="40" color="#39bf88" width="80%" class="text-capitalize white--text" style="border-radius: 10px"
                 @click="login">Login
          </v-btn>
        </v-col>
        <v-col cols="12" class="text-capitalize" style="color: #39bf88;font-size: 27px;font-weight: 800">
          <div @click="$router.push('/forgot-password')">
            Forgot Password?
          </div>
        </v-col>
      </v-row>
      <br>
    </div>
  </div>
</template>
<script>

export default {
  data: () => ({
    screenType: null,
    mobileTab: 'login',
    terms: null,
    loginCondition: false,
    registerCondition: false,
    err: false,
    msg: '',
    snackbar: false,
    userInstance: {
      first_name: null,
      last_name: null,
      phone: null,
      email: null,
      password: null,
      password_confirmation: null,
      policy: false,
      auto_mail: false,
    },
    credentials: {
      email: null,
      password: null,
    },
    valid: false,
    username: '',
    password: '',
    usernameRules: [
      v => !!v || 'Username is required',
    ],
    passwordRules: [
      v => !!v || 'Password is required',
    ],
  }),
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    getProfile() {
      window.axios.get('api/profile').then(() => {
        this.$router.push('/my-page')
        this.$store.state.loading = false
      }).catch((err) => {
        console.log(err)
        this.$store.state.loading = false
      })
    },
    login() {
      window.axios.post('api/auth/login', this.credentials).then((res) => {
        this.$store.state.user = res.data.data.user
        this.$store.state.auth = true
        this.$cookies.set('token', 'Bearer ' + res.data.data.token)
        this.$router.go(0)
      }).catch((err) => {
        if (err.response.status === 400 || err.response.status === 404) {
          this.snackbar = true
          this.msg = 'Your credentials does not match with our records.'
        }
        if (err.response.status === 422) {
          let errorText = ''
          Object.entries(err.response.data.errors).forEach((err) => {
            err[1].forEach((item) => {
              errorText = errorText + item + '<br>'
            })
          })
          this.snackbar = true
          this.msg = errorText
        } else {
          this.snackbar = true
          this.msg = err.response.data.errors.message
        }
      })
    },
    register() {
      window.axios.post('api/auth/register', this.userInstance).then((res) => {
        this.$store.state.user = res.data.data.user
        this.$store.state.auth = true
        this.$cookies.set('token', 'Bearer ' + res.data.data.token)
        this.$router.go(0)
      }).catch((err) => {
        if (err.response.status === 422) {
          let errorText = ''
          Object.entries(err.response.data.errors).forEach((err) => {
            err[1].forEach((item) => {
              errorText = errorText + item + '<br>'
            })
          })
          this.snackbar = true
          this.msg = errorText
        } else {
          this.snackbar = true
          this.msg = err.response.data.message
        }
      })
    }

  },
  watch: {
    '$store.state.loading'(value) {
      if (!value && this.$store.state.auth) {
        this.$router.push('/')
      }
    },
    'credentials.email'(value) {
      this.loginCondition = !!(value && this.credentials.password);
    },
    'credentials.password'(value) {
      this.loginCondition = !!(value && this.credentials.email);
    },
    'userInstance.first_name'(value) {
      this.registerCondition = !!(value && this.terms && this.userInstance.last_name && this.userInstance.email && this.userInstance.password && this.userInstance.password_confirmation);
    },
    'userInstance.last_name'(value) {
      this.registerCondition = !!(value && this.terms && this.userInstance.first_name && this.userInstance.email && this.userInstance.password && this.userInstance.password_confirmation);
    },
    'userInstance.email'(value) {
      this.registerCondition = !!(value && this.terms && this.userInstance.first_name && this.userInstance.last_name && this.userInstance.password && this.userInstance.password_confirmation);
    },
    'userInstance.password'(value) {
      this.registerCondition = !!(value && this.terms && this.userInstance.first_name && this.userInstance.last_name && this.userInstance.email && this.userInstance.password_confirmation);
    },
    'userInstance.password_confirmation'(value) {
      this.registerCondition = !!(value && this.terms && this.userInstance.first_name && this.userInstance.last_name && this.userInstance.email && this.userInstance.password);
    },
    'terms'(value) {
      this.registerCondition = !!(value && this.userInstance.password_confirmation && this.userInstance.first_name && this.userInstance.last_name && this.userInstance.email && this.userInstance.password);
    }
  },
  mounted() {
    this.getScreenType()
    this.getProfile()
    if (this.$route.query.NeedAuthPopup) {
      this.NOTIFICATION = 'Login to your account to use our products';
      this.NOTIFICATION_STATUS = true;
      this.NOTIFICATION_COLOR = 'red'
    }
  }
};
</script>
<style>
.title-login-register {
  font-size: 1.9vw !important;
  color: #39bf88 !important;
}

.custom-padding-left {
  padding-left: 5.5vw !important;
}

.login-fields {
  padding-left: 4.7vw !important;
  width: 70% !important;
}

.login-checkbox {
  padding-left: 5.9vw !important;
  font-size: 1vw !important;
}

.forgot-password-text {
  font-size: 1vw !important;
  color: #39bf88 !important;
}

.login-button {
  margin-left: 5vw !important;
  border-radius: 30px !important;
  width: 58% !important;
  height: 50px !important;
}

.register-button {
  border-radius: 30px !important;
  width: 49% !important;
  height: 50px !important;
}

.custom-y-margin {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}
</style>